import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";

import { AsyncDependencyBoth } from "../base-classes/async-dependency-both";
import { Child } from "../models/people/child.class";
import { Logger } from "../providers/logger";

import { AccountService } from "./account.service";
import { BackendCallService } from "./backend-call.service";

@Injectable({
  providedIn: "root",
})
export class ChildService extends AsyncDependencyBoth implements OnDestroy {
  private subscriptions: Subscription[] = [];

  private children: BehaviorSubject<Child[]> = new BehaviorSubject<Child[]>([]);

  constructor(
    private backend: BackendCallService,
    private acc_service: AccountService
  ) {
    super();
    this.init(backend, acc_service);
  }

  protected onReady(): void {
    this.subscriptions.push(
      this.acc_service.get_account$().subscribe(async (account) => {
        if (account) {
          await this.load_children();
        }
        this.set_ready();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get_children$(): Observable<Child[]> {
    return this.children.asObservable();
  }
  public get_children(): Child[] {
    return this.children.getValue();
  }
  public get_child(pk: number): Child {
    return this.get_children().find((child) => child.pk === pk);
  }

  private set_children(children: Child[]): void {
    this.children.next(children.filter(child => child));
  }

  /**
   * erstellt ein Kind in der DB mit den übergebenen Daten
   * used by profile page
   * @param child object
   * @returns object - response / leeres Objekt
   */
  public create(child: Child): Promise<Child> {
    return this.api_create_child(child).then((saved_child) => {
      if (saved_child) { this.set_children([...this.get_children(), saved_child]); }
      return saved_child;
    });
  }

  /**
   * bearbeitet ein Kind in der DB
   * used by profile page
   * @params child object
   * @returns object - response oder leeres Objekt
   */
  public update(child: Child): Promise<void> {
    return this.api_update_child(child).then(() => {
      const children = [
        ...this.get_children().filter((c) => c.pk !== child.pk),
        child,
      ];
      this.set_children(children);
    });
  }

  /**
   * löscht ein Kind
   * used by profile page
   * @param child object
   */
  public async remove(child: Child): Promise<void> {
    const success = await this.api_remove_child(child);
    if (success) {
      this.set_children(this.get_children().filter((c) => c.pk !== child.pk));
    }
  }

  private async load_children(): Promise<void> {
    const children = (await this.api_get_children()).map((child) => {
      child = Child.from_json(child);
      delete (child as any).familycard;
      return child;
    });
    this.set_children(children);
  }

  // *************************************
  // **** Backend API functions start ****
  // *************************************

  /**
   * holt alle Kinder aus der DB
   * used by app component
   * @returns any[] - Kinderdaten
   */
  private async api_get_children(): Promise<Child[]> {
    const url = `${this.backend.get_backend_domain()}/api/user/children/`;

    return this.backend
      .get_with_token<Child[]>(url)
      .then((children) =>
        (children || []).map((child) => Child.from_json(child))
      )
      .catch(() => []);
  }

  /**
   * erstellt ein Kind in der DB mit den übergebenen Daten
   * used by profile page
   * @param child object
   * @returns object - response / leeres Objekt
   */
  private api_create_child(child: Child): Promise<Child> {
    child = Child.to_json(child);
    return this.backend
      .post_with_token<Child>(
        `${this.backend.get_backend_domain()}/api/user/child/create/`,
        child
      )
      .then((created_child) => {
        return Child.from_json(created_child);
      })
      .catch((err) => {
        Logger.error("Kind/Teilnehmer konnte nicht hinzugefügt werden!", {error: err, first_name: child.first_name});
        return undefined;
      });
  }

  /**
   * bearbeitet ein Kind in der DB
   * used by profile page
   * @params child object
   * @returns object - response oder leeres Objekt
   */
  private api_update_child(child: Child): Promise<Child> {
    const url = `${this.backend.get_backend_domain()}/api/user/child/${
      child.pk
    }`;
    child = Child.to_json(child);

    return this.backend
      .put_with_token<Child>(url, child)
      .then((saved_child) => Child.from_json(saved_child))
      .catch(() => undefined);
  }

  /**
   * entfernt ein Kind aus der DB endgültig
   * @param child object
   * @returns boolean - success true / fail error
   */
  private api_remove_child(child: Child): Promise<boolean> {
    const url = `${this.backend.get_backend_domain()}/api/user/child/${
      child.pk
    }`;

    return this.backend
      .delete_with_token(url)
      .then(() => true)
      .catch(() => false);
  }
  // *************************************
  // **** Backend API functions end ******
  // *************************************
}
